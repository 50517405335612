<template>
  <div v-loading="loading">
    <el-card shadow="never" style="margin-top:20px">
      <div slot="header">
        <span class="iconfont icon-wifi">&nbsp;热点管理</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="openDialogDefaultWifi">热点预设</el-button>
          <el-button size="small" @click="openBatch" v-show="dataLineSelections.length>0">批量编辑</el-button>&nbsp;
          <el-dropdown
            placement="bottom"
            trigger="click"
            @command="onSubmitBatchSwitch"
            v-show="dataLineSelections.length>0"
          >
            <el-button size="small">
              批量开关
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="on">开启</el-dropdown-item>
              <el-dropdown-item command="off">关闭</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div>
          <el-input
            v-model="search"
            placeholder="请输入用户账号、名称"
            suffix-icon="el-icon-search"
            style="width:300px"
            size="small"
            @change="initLoad"
          ></el-input>
        </div>
      </div>
      <el-table
        ref="table"
        :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        size="mini"
        @selection-change="selectionLineChangeHandle"
        row-key="id"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column prop="room" label="房间号"></el-table-column>
        <el-table-column prop="wirelessMac" label="无线mac"></el-table-column>
        <el-table-column prop="mac" label="有线mac"></el-table-column>
        <el-table-column prop="clientModel" label="设备型号"></el-table-column>
        <el-table-column prop="wifiSwitch" label="WIFI开关">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.wifiSwitch"
              active-value="on"
              inactive-value="off"
              @change="onChangeWifiSwitch(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="wifiFrequency" label="发射频率">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.wifiFrequency"
              size="mini"
              @change="onChangeWifiFrequency(scope.row)"
            >
              <el-option
                v-for="item in frequency"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>-->
        <el-table-column prop="wifiName" label="WIFI名称">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.isSelectedWifiName"
              size="mini"
              v-model="scope.row.wifiName"
              @focus="focusNameEvent(scope.row)"
              @blur="blurNameEvent(scope.row)"
              v-focus
            ></el-input>
            <span v-else>
              {{ scope.row.wifiName }}
              <i
                class="el-icon-edit-outline"
                style="color:#5e72f9;font-size:14px;margin-left:10px;cursor:pointer;vertical-align:middle"
                @click="cellNameClick(scope.row)"
              ></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="wifiPassword" label="WIFI密码">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.isSelectedWifiPassWord"
              size="mini"
              v-model="scope.row.wifiPassword"
              @focus="focusPassWordEvent(scope.row)"
              @blur="blurPassWordEvent(scope.row)"
              v-focus
            ></el-input>
            <span v-else>
              {{ scope.row.wifiPassword }}
              <i
                class="el-icon-edit-outline"
                style="color:#5e72f9;font-size:14px;margin-left:10px;cursor:pointer;vertical-align:middle"
                @click="cellPassWordClick(scope.row)"
              ></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 批量修改wifi名称密码 -->
    <el-dialog
      :visible.sync="dialogEditWifi"
      :close-on-click-modal="false"
      width="30%"
      @closed="closeDialogEditWifi"
    >
      <span slot="title">批量编辑</span>
      <el-form
        :model="editWifiForm"
        ref="editWifiForm"
        label-width="120px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item>
          <el-radio-group v-model="editWifiForm.wifiType" size="mini">
            <el-radio-button label="unitive">统一名称</el-radio-button>
            <el-radio-button label="room">房间号自动分配</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="WIFI名称"
          v-if="editWifiForm.wifiType=='unitive'"
          prop="wifiUnitiveName"
          :rules="{ required: true, message: '请输入WIFI名称', trigger: 'blur' }"
        >
          <el-input v-model="editWifiForm.wifiUnitiveName" placeholder="请输入WIFI名称" maxlength="30" clearable></el-input>
          <span>示例:***酒店</span>
        </el-form-item>

        <el-form-item
          label="WIFI名称"
          v-else
          prop="wifiRoomName"
        >
          <el-input v-model="editWifiForm.wifiRoomName" placeholder="请输入WIFI前缀 例：WIFI-" maxlength="20" clearable>
            <template slot="append">房间号</template>
          </el-input>
          <span>示例:WIFI-房间号</span>
        </el-form-item>
        <el-form-item
          label="WIFI密码"
          prop="wifiPassword"
          :rules="{ required: true, min: 8, max: 32, message: '请输入8-32位WIFI密码', trigger: 'blur' }"
        >
          <el-input v-model="editWifiForm.wifiPassword" placeholder="请输入WIFI密码" maxlength="32" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditWifi=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitBatchWifi">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 预设热点 -->
    <el-dialog :visible.sync="dialogPresetsWifi" :close-on-click-modal="false" width="30%">
      <span slot="title">预设热点</span>
      <el-form
        :model="defaultWifiFrom"
        ref="defaultWifiFrom"
        label-width="80px"
        size="small"
        hide-required-asterisk
        style="padding:20px"
      >
        <el-form-item>
          <el-radio-group v-model="defaultWifiFrom.wifi_type">
            <el-radio-button label="unitive">统一名称</el-radio-button>
            <el-radio-button label="room">房间号自动分配</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="WIFI名称"
          v-if="defaultWifiFrom.wifi_type=='unitive'"
          prop="wifiUnitive_name"
          :rules="{ required: true, message: '请输入WIFI名称', trigger: 'blur' }"
        >
          <el-input v-model="defaultWifiFrom.wifiUnitive_name" placeholder="请输入WIFI名称" maxlength="30" clearable></el-input>
          <span>示例:***酒店</span>
        </el-form-item>

        <el-form-item
          label="WIFI名称"
          v-else
          prop="wifiRoom_name"
        >
          <el-input v-model="defaultWifiFrom.wifiRoom_name" placeholder="请输入WIFI前缀 例：WIFI-" maxlength="20" clearable>
            <template slot="append">房间号</template>
          </el-input>
          <span>示例:WIFI-房间号</span>
        </el-form-item>
        <el-form-item
          label="WIFI密码"
          prop="wifi_password"
          :rules="{ required: true, min: 8, max: 32, message: '请输入8-32位WIFI密码', trigger: 'blur' }"
        >
          <el-input v-model="defaultWifiFrom.wifi_password" placeholder="请输入WIFI密码" maxlength="32" clearable></el-input>
        </el-form-item>
        <el-form-item
          label="开启WIFI">
          <el-switch
            v-model="defaultWifiFrom.wifi_switch"
            active-value="on"
            inactive-value="off"
            @change="wifiSwitch"
          ></el-switch>
        </el-form-item>
        <el-form-item label="总是显示">
          <el-switch
            active-value="on"
            inactive-value="off"
            v-model="defaultWifiFrom.wifi_is_always_displayed"
            @change="mainSwitch"
          ></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogPresetsWifi=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitDefaultWifi">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getTerminalApi,
  editTerminalApi,
  batchEditTerminalApi,
  getCurrencyApi,
  editCurrencyApi
} from "../../api/data";
import Aes from "../../utils/aes";
export default {
  data() {
    return {
      loading: true,
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      dialogPresetsWifi: false, //关闭展开 预设wifi页面
      search: "", //查询条件
      list: [],
      defaultWifiFrom: {
        wifi_switch: "off",
        wifi_frequency: "2.4",
        wifiUnitive_name: "",
        wifiRoom_name: "",
        wifi_name: "",
        wifi_password: "",
        wifi_type: "", // unitive room
        wifi_is_always_displayed: "off"
      },
      //发射频率
      frequency: [
        { value: "2.4", label: "2.4G" },
        { value: "5", label: "5G" }
      ],
      dialogEditWifi: false, //批量编辑窗口
      editWifiForm: {
        wifiUnitiveName: "",
        wifiRoomName: "",
        wifiName: "",
        wifiPassword: "",
        wifiType: "unitive" // unitive room
      },
      //勾选中的集合
      dataLineSelections: []
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  //自定义指令对文本框添加焦点
  directives: {
    focus: {
      inserted: function(el) {
        el.querySelector("input").focus();
      }
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    mainSwitch() {
      this.defaultWifiFrom.wifi_switch == "on" ? "off" : "on";
    },
    wifiSwitch() {
      this.defaultWifiFrom.wifi_is_always_displayed == "off" ? "on" : "off";
    },
    initLoad() {
      getTerminalApi({
        groupId: this.placesInfo.groupId,
        param: this.search,
        clientType: "all"
      }).then(res => {
        this.loading = false;
        this.list = res.data.map(item => {
          return {
            ...item,
            isSelectedWifiName: false,
            isSelectedWifiPassWord: false
          };
        });
      });
    },
    //打开预设热点窗口并且初始化默认WIFI配置
    openDialogDefaultWifi() {
      getCurrencyApi({ groupId: this.placesInfo.groupId }).then(res => {
        console.log(res);
        if (res.data.wifi_type == "unitive") {
          this.defaultWifiFrom.wifiUnitive_name = res.data.wifiUnitive_name;
        } else {
          this.defaultWifiFrom.wifiRoom_name = res.data.wifi_name;
        }
        this.defaultWifiFrom.wifi_switch = res.data.wifi_switch;
        this.defaultWifiFrom.wifi_frequency = res.data.wifi_frequency;

        this.defaultWifiFrom.wifi_type = res.data.wifi_type;
        this.defaultWifiFrom.wifi_password = res.data.wifi_password;
        this.defaultWifiFrom.wifi_is_always_displayed =
          res.data.wifi_is_always_displayed;
        this.defaultWifiFrom.wifi_switch = res.data.wifi_switch;
      });
      this.dialogPresetsWifi = true;
    },
    //保存默认WiFi配置
    onSubmitDefaultWifi() {
      this.$refs["defaultWifiFrom"].validate(valid => {
        if (valid) {
          if (this.defaultWifiFrom.wifi_type == "unitive") {
            this.defaultWifiFrom.wifiz_name = this.defaultWifiFrom.wifiUnitive_name;
          } else {
            this.defaultWifiFrom.wifi_name = this.defaultWifiFrom.wifiRoom_name;
          }
          this.defaultWifiFrom.groupId = this.placesInfo.groupId;
          editCurrencyApi(this.defaultWifiFrom).then(res => {
            console.log(res);
            if (res) {
              this.$message.success(res.msg);
              this.dialogPresetsWifi = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //勾选事件
    selectionLineChangeHandle(val) {
      const selectionList = [];
      for (let i = 0; i < val.length; i++) {
        selectionList.push(val[i].id);
      }
      this.dataLineSelections = selectionList;
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    //打开批量编辑窗口
    openBatch() {
      if (this.dataLineSelections.length == 0) {
        this.$message.warning("请至少勾选一项");
        return;
      }
      this.dialogEditWifi = true;
    },
    //批量修改wifi开关
    onSubmitBatchSwitch(command) {
      if (command == "on") {
        batchEditTerminalApi({
          ids: this.dataLineSelections,
          wifiSwitch: "on",
          wifiType: "unitive"
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      } else {
        batchEditTerminalApi({
          ids: this.dataLineSelections,
          wifiSwitch: "off",
          wifiType: "unitive"
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      }
    },
    //批量修wifi账号密码
    onSubmitBatchWifi() {
      this.$refs["editWifiForm"].validate(valid => {
        if (valid) {
          if (this.editWifiForm.wifiType == "unitive") {
            this.editWifiForm.wifiName = this.editWifiForm.wifiUnitiveName;
          } else {
            this.editWifiForm.wifiName = this.editWifiForm.wifiRoomName;
          }
          batchEditTerminalApi({
            ids: this.dataLineSelections,
            wifiType: this.editWifiForm.wifiType,
            wifiName: this.editWifiForm.wifiName,
            wifiPassWord: this.editWifiForm.wifiPassword
          }).then(res => {
            if (res) {
              this.dialogEditWifi = false;
              this.$message.success(res.msg);
              this.initLoad();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量修改WiFi窗口关闭事件
    closeDialogEditWifi() {
      this.$refs["editWifiForm"].resetFields();
    },
    //设置wifi频率事件
    onChangeWifiFrequency(row) {
      editTerminalApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //设置wifi开关
    onChangeWifiSwitch(row) {
      editTerminalApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //编辑WIFI名称事件
    cellNameClick(row) {
      row.isSelectedWifiName = !row.isSelectedWifiName;
    },
    //触发焦点记录用户聚焦前的值
    focusNameEvent(row) {
      row.oldWifiName = row.wifiName;
    },
    //失去焦点事件
    blurNameEvent(row) {
      //判断前后两次的值是否一样
      if (row.wifiName !== row.oldWifiName) {
        editTerminalApi(row).then(res => {
          if (res) {
            row.isSelectedWifiName = !row.isSelectedWifiName;
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      }
    },
    //编辑WIFI名称事件
    cellPassWordClick(row) {
      row.isSelectedWifiPassWord = !row.isSelectedWifiPassWord;
    },
    //触发焦点记录用户聚焦前的值
    focusPassWordEvent(row) {
      row.oldWifiPassWord = row.wifiPassWord;
    },
    //失去焦点事件
    blurPassWordEvent(row) {
      //判断前后两次的值是否一样
      if (row.wifiPassword !== row.oldWifiPassWord) {
        editTerminalApi(row).then(res => {
          if (res) {
            row.isSelectedWifiPassWord = !row.isSelectedWifiPassWord;
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      }
    }
  }
};
</script>
<style lang='scss'>
</style>
